import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';

//骑手管理接口数据
export interface RefundData {
    buyer_nickname: string;
    check_time: number;
    count: number;
    create_time: number;
    disagree_reason: string;
    disagree_reason_image: string;
    express_address: string;
    express_mobile: string;
    express_name: string;
    goods_image_url: string;
    goods_price: number;
    goods_title: string;
    id: number;
    order_goods_id: number;
    order_id: number;
    product_id: number;
    reason_detail: string;
    reason_image_url: string;
    reason_type: number;
    reject_reason: string;
    status: number;
    user_id: number;
    warehouse_id: number;
    warehouse_name: string;
  }
  export function initRefundData(remote?: PaginationData<RefundData>): PaginationData<RefundData> {
    remote = remote || {} as PaginationData<RefundData>;
    return {
      current: remote.current || 0,
      detail: remote.detail || [],
      last: remote.last || 0,
      limit: remote.limit || 0,
      total: remote.total || 0,
    }
  }