
import { Component, Vue } from 'vue-property-decorator';
import { getList, exportData, QueryParams, expendApi, incomeApi } from '@/api/finance/finance';
import moment from 'moment';
interface SearchParams {
  transaction_type: number;
  transaction_number: number; //第三方平台交易编号
  order_id: number; //本系统订单id
  create_time_range: Array<number>; //时间范围
  is_export: number;
}
@Component({
  name:'FinanceOrder'
})
export default class Finance extends Vue {
  is_loading: boolean = false;
  accumulative_total_sales: number = 0;
  total_amount_spend: number = 0;
  search = this.$form.createForm(this, { name: 'code' });
  lists: any = [];
  create_time_range = [];
  form: any;
  search_data: SearchParams = {
    transaction_type: 0,
    transaction_number: 0, //第三方平台交易编号
    order_id: 0, //本系统订单id
    create_time_range: [], //时间范围
    is_export: 0, // 是否导出
  };
  limit = 20;
  total = 0;
  current = 1;
  pagination = {};
  columns = [
    {
      title: '流水编号',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '类型',
      dataIndex: 'transaction_type_name',
      key: 'transaction_type_name',
    },
    {
      title: '金额（元）',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '支付方式',
      dataIndex: 'pay_type_name',
      key: 'pay_type_name',
    },
    {
      title: '交易编号',
      dataIndex: 'transaction_number',
      key: 'transaction_number',
    },
    {
      title: '订单ID',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: '费用发生时间',
      dataIndex: 'create_time_format',
      key: 'create_time_format',
    },
  ];

  paginationData() {
    return {
      current: this.current,
      pageSize: this.limit,
      showTotal: () => `共${this.total}条, 每页${this.limit}条`,
      total: this.total,
    };
  }
  async tableChange(data: any){
    this.current = data.current
    await this.getList()
  }
  // 获得列表数据
  async getList() {
    const search_data: QueryParams = {
      current: this.current,
      transaction_type: this.search_data.transaction_type ? +this.search_data.transaction_type : 0,
      transaction_number: this.search_data.transaction_number, //第三方平台交易编号
      limit: this.limit,
      order_id: Number(this.search_data.order_id), //本系统订单id
      create_time_range: this.search_data.create_time_range.join(','), //时间范围
      is_export: this.search_data.is_export,
    };
    for (const key in search_data) {
      if (!search_data[key] && key != 'transaction_type' && key != 'is_export')
        delete search_data[key];
    }
    // 导出
    if (this.search_data.is_export) {
      try {
        const r: any = await exportData(search_data);
        if (r.status === 402) {
          this.$message.error(r.message);
        }
      } catch (error) {
        // window.location.href = `https:${error}`;
      }
    } else {
      const {
        data: { detail, total },
      } = await getList(search_data);
      this.lists = [];
      this.total = total;
      //  数据处理
      if (detail.length != 0) {
        detail.forEach((item: any) => {
          const obj: any = JSON.parse(
            JSON.stringify({
              id: item.id,
              transaction_type: item.transaction_type,
              transaction_type_name: this.transactionTypeName(item.transaction_type),
              amount: item.amount / 100,
              pay_type: item.pay_type,
              pay_type_name: this.peyType(item.pay_type),
              transaction_number: item.transaction_number,
              order_id: item.order_id,
              create_time: item.create_time,
              create_time_format: moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
            })
          );
          this.lists.push(obj);
        });
      }
    }
  }
  // 类型处理
  transactionTypeName(type: number) {
    let res = '全部';
    switch (type) {
      case 1:
        res = '收入-订单支付';
        break;
      case 2:
        res = '支出-订单退款';
        break;
      default:
        break;
    }
    return res;
  }
  // 支付类型处理
  peyType(type: number) {
    let res = '-';
    switch (type) {
      case 1:
        res = '微信';
        break;
      case 2:
        res = '支付宝';
        break;
      default:
        break;
    }
    return res;
  }
  // 获取收入
  async getIncome() {
    const {
      data: { amount },
    } = await incomeApi();
    this.accumulative_total_sales = amount / 100;
  }
  // 获取支出
  async getExpend() {
    const {
      data: { amount },
    } = await expendApi();
    this.total_amount_spend = amount / 100;
  }
  // 搜索
  handleSubmit(e: any, type: number = 0) {
    e?.preventDefault?.();
    this.form.validateFields((err: any, values: any) => {
      if (!err) {
        const start_time =
          values.time && values.time.length
            ? moment(values.time[0])
                .startOf('day')
                .unix()
            : 0;
        const end_time =
          values.time && values.time.length
            ? moment(values.time[1])
                .endOf('day')
                .unix()
            : 0;
        this.search_data = {
          transaction_type: values.transaction_type,
          transaction_number: values.transaction_number, //第三方平台交易编号
          order_id: values.order_id, //本系统订单id
          create_time_range: start_time && end_time ? [start_time, end_time] : [], //时间范围
          is_export: type,
        };
        // if (type) return exportData(this.search_data);
        // else return
        this.getList();
      }
    });
  }
  // 清除搜索条件
  clear() {
    this.form.resetFields();
    this.handleSubmit(undefined)
  }
  // // 导出
  // async exportData(query_params: SearchParams) {
  //   // const query_params = {
  //   //   is_export: 1,
  //   // };
  // }
  async created() {
    await this.getList();
    await this.getIncome();
    await this.getExpend();
  }
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'search' });
  }
}
