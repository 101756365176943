
import { request as axios } from '@/util/request';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Moment } from 'moment';
import { PaginationData } from '@/api/operation/customer';
import { RefundData,initRefundData } from '@/api/rider/riderList'
import moment from 'moment';
import { changeLoading } from '@/util/decorators';
interface Form {
  tixian_value: string;//提现单号
  search_value: string;//骑手输入框
  search_type: number;//选择骑手姓名或者电话
  reason_value: number;//单据状态
  order_time: Moment[];//申请时间
  refund_type: number;//状态
  yunying_value: string;
  caiwu_value: string;
  jiaoyi_value: string;
}
const SEARCH_TYPE=[
    {
    id: 0,
    value: '骑手ID',
  },
  {
    id: 1,
    value: '骑手姓名',
  },
  {
    id: 2,
    value: '骑手电话',
  },
]
const REFUND_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待审核',
  },
  {
    id: 2,
    value: '审核通过',
  },
  {
    id: 3,
    value: '审核不通过',
  },
];
export const RECOMMEND_LIST: any = [
  {
    value: 20,
    label: '财务待审核',
  },
  {
    value: 25,
    label: '财务审核通过',
  },
  {
    value: 30,
    label: '财务审核不通过',
  },
  {
    value: 40,
    label: '打款成功',
  },
  {
    value: 50,
    label: '打款失败',
  }
];
function getFormData() {
  return {
    tixian_value:'',
    search_value: '',
    search_type: 0,
    reason_value: 0,
    order_time: [],
    refund_type:0,
    yunying_value:'',
    caiwu_value:'',
    jiaoyi_value:''
  };
}
@Component({
  name:'FinanceWithdrawal'
})
export default class FinanceWithdrawal extends Vue{
  is_loading: boolean = false;
  audioPlay=false //是否开始自动播放
  form: Form = getFormData();
  ware_category_list: any = [];//意向区域
  rider_dis: boolean=false;//提现订单弹窗显隐
  shenhe_dis: boolean=false//审核弹窗显隐
  koukuan_money_err: boolean=false //扣款错误提示
  koukuan_money: number=0;//扣款金额
  passBecause_err: boolean=false;//备注提示
  result_dis: boolean=false;//审核结果弹窗是否显示
  passBecause: string=''//备注
  shenheYes: number=25 //审核是否通过
  dakuan_info: boolean=false;//打款信息弹窗
  fail: boolean=false;//打款失败信息弹窗
  selectedRowKeys= []
  recommend_list: any = RECOMMEND_LIST;
  result=[
    {
      name:'审核结果',
      body:'不通过'
    },
    /* {
      name:'扣款金额（元）',
      body:10
    }, */
    {
      name:'财务审核备注',
      body:'客诉扣款吧啦啦啦'
    },
    {
      name:'审核人',
      body:'fgajkfdjak'
    },
    {
      name:'审核时间',
      body:'2022-02'
    },
  ]
  dakuanInfo=[
    {
      name:'打款人',
      body:'',
      dis:true
    },
    {
      name:'打款时间',
      body:'',
      dis:true
    },
    {
      name:'交易单号',
      body:'',
      dis:true
    },
    {
      name:'交易时间',
      body:'',
      dis:true
    },
    {
      name:'交易金额',
      body:0,
      dis:true
    },
    {
      name:'失败原因',
      body:'',
      dis:false
    },
  ]
  dakuanResult=''//打款结果
  tostal: boolean=false;//打款后弹窗
  onSelectChange(selectedRowKeys: any) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    }
  //确定
    handleOk(){
        this.rider_dis=false;
    }
    //取消
    handleCancel(){
        this.rider_dis=false;
    }
    /**
   * @description 验证是否最多两位小数的数字
   * @param {string} number
   * @returns {number}
   */
  validateDouble(number: string): boolean {
   // if (Number(number) === 0) return false;
    return /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(number);
  }
    //点击审核
    id=''
    clickShenhe(record: any){
      this.shenhe_dis=true
      this.id=record.id
    }
    txcurrent: number=1
  paginationOptions(data: any) {
    this.txcurrent=data.current || 1
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
    //点击提现订单数
    tider_table: any
    async clickRider(record: any){
      console.log(record)
      const body: any={
        withdraw_id:record.id,
        current:this.txcurrent
      }
      const res=await axios.get('/boss/knight/withdraw/order',{
      params:{
        ...body
      }
    })
      console.log(res)
      this.tider_table=res.data
      this.rider_dis=true
    }
    //打款
    async dakuan(record: any){
      const res: any=await axios.post(`/boss/finance/knight/payment`,{
        ids:record.id
      })
      if(res.status==200){
        //this.$message.success('打款成功')
        this.dakuanResult='打款成功'
        this.fail=true
      }else{
        this.dakuanResult=res.message
        this.fail=true
      }
      await this.getData()
    }
    //批量打款
    async pldakuan(){
      let ids=''
      this.selectedRowKeys.forEach((item: any)=>{
        ids=ids+item+','
      })
      if(ids==''){
        this.$message.error('请选择要打款的单子')
        return false
      }else{
        const res: any=await axios.post(`/boss/finance/knight/payment`,{
        ids:ids
        })
        this.dakuanResult=res.message
        this.fail=true
        await this.getData()
      }
      
      /* if(res.status==200){
        this.$message.success('打款成功')
      }else{
        this.$message.error('打款失败')
      } */
    }
    //打款信息
    clickInfo(record: any){
      const res: any=this.refund_data.detail.filter((item: any)=>{
        return item.id==record.id
      })
      console.log(JSON.parse(JSON.stringify(res)))
      if(res[0].status==40){
        this.dakuanInfo[2].dis=true
        this.dakuanInfo[3].dis=true
        this.dakuanInfo[4].dis=true
        this.dakuanInfo[5].dis=false
      }else{
        this.dakuanInfo[2].dis=false
        this.dakuanInfo[3].dis=false
        this.dakuanInfo[4].dis=false
        this.dakuanInfo[5].dis=true
      }
      this.dakuanInfo[0].body=res[0].finance_operator
      this.dakuanInfo[1].body=res[0].payment_time!=0?moment(res[0].payment_time*1000).format('YYYY-MM-DD HH:mm:ss') :''
      this.dakuanInfo[2].body=res[0].transaction_number
      this.dakuanInfo[3].body=res[0].payment_time!=0? moment(res[0].payment_time*1000).format('YYYY-MM-DD HH:mm:ss'):''
      this.dakuanInfo[4].body=res[0].reality_money/100
      this.dakuanInfo[5].body=res[0].fail_reason
      this.dakuan_info=true
    }
    //审核结果
    clickRusult(record: any){
      switch(record.status){
        case 1:
          this.result[0].body="待运营审核"
          break
        case 10:
          this.result[0].body="运营审核不通过"
          break
        case 20:
          this.result[0].body="待财务审核"
          break
        case 25:
          this.result[0].body="财务审核通过"
          break
        case 30:
          this.result[0].body="财务审核不通过"
          break
        case 40:
          this.result[0].body="打款成功"
          break
        case 50:
          this.result[0].body="打款失败"
          break
        default:
          this.result[0].body=""
      }
      //this.result[1].body=record.cut_money/100
      this.result[1].body=record.finance_remark
      this.result[2].body=record.finance_user
      this.result[3].body=moment(record.finance_time*1000).format('YYYY-MM-DD HH:mm:ss') ; 
      this.result_dis=true
    }
    //审核确定
    async shenheOk(){
        //后端接口
        const body={
          status:this.shenheYes,
          remark:this.passBecause
        }
        const res = await axios.put(`/boss/finance/knight/withdraw/${this.id}`,body)
        if(res.status==200){
          this.getData()
          this.$message.success('审核成功')
          this.shenhe_dis=false
        }else{
          this.$message.error('审核失败')
        }
      
    }
    //审核取消
    shenheCancel(){
      this.shenhe_dis=false
    }
  //搜索条件
  get searchType() {
    return SEARCH_TYPE;
  }
  //骑手状态
  get refundStatus() {
    return REFUND_STATUS;
  }
  refund_data: PaginationData<RefundData> = initRefundData();
  //获取表格数据
  async getData(body?: any){
    const bodys: any={
      ...body,
      current:this.current,
      limit:10
    }
    const res = await axios.get('/boss/finance/knight/withdraw',{
      params:{
        ...bodys
      }
    })
    if(res.status==200){
      this.refund_data=res.data
    }
  }
  //搜索
  find(){
    console.log(this.form.reason_value)
    const start_time = this.form.order_time[0]
      ? Math.floor(+this.form.order_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.order_time[1]
      ? Math.floor(+this.form.order_time[1].format('x') / 1000)
      : '';
    const send_data: any={
      withdraw_number:this.form.tixian_value,
      register_id:this.form.search_value,
      name:this.form.search_value,
      mobile:this.form.search_value,
      apply_start_time:start_time,
      apply_end_time:end_time,
      remark:this.form.yunying_value,
      finance_remark:this.form.caiwu_value,
      transaction_number:this.form.jiaoyi_value,
      status:this.form.reason_value
    }
    if(this.form.tixian_value==''){
      delete send_data.withdraw_number
    }if(this.form.search_value==''){
      delete send_data.register_id
      delete send_data.name
      delete send_data.mobile
    }if(this.form.search_type==0){
      delete send_data.name
      delete send_data.mobile
    }if(this.form.search_type==1){
      delete send_data.register_id
      delete send_data.mobile
    }if(this.form.search_type==2){
      delete send_data.register_id
      delete send_data.name
    }if(this.form.order_time.length<=0){
      delete send_data.order_time
    }if(this.form.yunying_value==''){
      delete send_data.remark
    }if(this.form.caiwu_value==''){
      delete send_data.finance_remark
    }if(this.form.jiaoyi_value==''){
      delete send_data.transaction_number
    }if(this.form.reason_value==0){
      delete send_data.status
    }
    this.getData(send_data)
  }
  //清空
  clear(){
    this.form.tixian_value=''
    this.form.search_type=0
    this.form.search_value=''
    this.form.order_time=[]
    this.form.yunying_value=''
    this.form.caiwu_value=''
    this.form.jiaoyi_value=''
    this.form.reason_value=0
  }
  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    console.log(data)
    this.current=data.current
    const send_data={
      limit:10,
      current:data.current
    }
    await this.getData(send_data);
  }
  //分页
  current: number=1
  paginationOption(data: any) {
    //this.current=data.current || 1
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  async created() {
    await this.getData()
  }
}
