const { VUE_APP_API: FCREST } = process.env;
const getUrl = require('baimahu/url-with-param');
import { request as axios } from '@/util/request';
import fileDownload from 'js-file-download';

const API = {
  list: '/boss/finance/account/flow', //财务列表
  income: '/boss/finance/account/income', //总收入
  expend: '/boss/finance/account/expend', //总支出
  export: `${FCREST}/boss/finance/account/flow/export`, // 导出
};

// 1：交易类型 0：全部，1：收入-订单支付，2：支出-订单退款
export interface QueryParams {
  current: number;
  transaction_type: number;
  transaction_number: number; //第三方平台交易编号
  limit: number;
  order_id: number; //本系统订单id
  create_time_range: string; //时间范围
  [x: string]: any;
}

// 财务流水列表
export async function getList(query_params: QueryParams) {
  const arr: Array<string> = [];
  for (const key in query_params) {
    const params: any = query_params[key];
    arr.push(key + '=' + params);
  }
  return await axios.get(API.list + '?' + arr.join('&'));
}
// 总收入
export async function incomeApi() {
  return await axios.get(API.income);
}
// 总支出
export async function expendApi() {
  return await axios.get(API.expend);
}

// 导出
export async function exportData(query_params: any) {
  const arr: Array<string> = [];
  for (const key in query_params) {
    const params: any = query_params[key];
    arr.push(key + '=' + params);
  }
  return new Promise((resolve, reject) => {
    axios
      .get( API.export+ '?' + arr.join('&'), { responseType: 'blob' })
      .then(res => {
        fileDownload(res as any,'订单流水.xlsx');

        resolve(res);
      })
      .catch(() => {
        reject(`${FCREST}${API.list + '?' + arr.join('&')}`);
      });
  });
}
